import styled from "@emotion/styled"
import React from "react"
import tw from "tailwind.macro"

const HrContainer = styled.hr`
  ${tw`
    my-6
  `}
`

const Divider = () => <HrContainer />
export default Divider
