import Layout from "@core/components/layout"
import Post from "@post/components/post"
import Divider from "@shared/components/divider"
import { graphql, Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => (
  <>
    <Helmet title="Blog - dctcheng" defer={false} />
    <Layout>
      {data.allMarkdownRemark.edges.map((edge, i, arr) => {
        const { title, date } = edge.node.frontmatter
        const post = {
          title: <Link to={edge.node.fields.slug}>{title}</Link>,
          date,
          content: edge.node.html,
        }
        const postElement = <Post key={i} post={post} />
        return arr.length - 1 === i ? (
          postElement
        ) : (
          <>
            {postElement}
            <Divider />
          </>
        )
      })}
    </Layout>
  </>
)

export const query = graphql`
  query PostsQuery {
    allMarkdownRemark(sort: { fields: fields___slug, order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "DD MMM YYYY")
            title
          }
        }
      }
    }
  }
`

export default IndexPage
